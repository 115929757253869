import singleSpaReact from 'single-spa-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { AuthorizationFormEntryPoint } from '@front-office/app-authorization';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: AuthorizationFormEntryPoint,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
